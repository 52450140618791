import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { IoMdPause, IoMdPlay } from 'react-icons/io';
import { FaFacebookSquare, FaInstagram } from 'react-icons/fa';

import logo from '../../assets/logo.png';
import radiosNet from '../../assets/logo-radios-512.png';

import './styles.css';

export default function Header() {
  const [playing, setPlaying] = useState(false);
  const audio = useRef(null);

  const togglePlaying = () => {
    setPlaying(!playing);
    !playing ? audio.current.play() : audio.current.pause();
  };

  return (
    <>
      <div className='header'>
        <div className='logo'>
          <img src={logo} alt='Universidade FM' />
        </div>

        <div className='radio'>
          <p>Ouça a Universidade FM</p>

          <div className='player'>
            <div>
              {playing ? <IoMdPause onClick={togglePlaying} /> : <IoMdPlay onClick={togglePlaying} />}
              <audio ref={audio} src="https://ssl.srvstm.com:7264/;?type=https&nocache=1" type="audio/mpeg" />
            </div>
          </div>
        </div>
      </div>

      <div className='navbar'>
        <ul>
          <li>
            <Link className='animation' to='/' >Programação</Link>
          </li>
          <li>
            <Link className='animation' to='/contato' >Contatos</Link>
          </li>
          <li>
            <Link className='animation' to='/sobre-nos'>A Rádio</Link>
          </li>
        </ul>

        <ul>
          <li>
            <a href="https://www.facebook.com/universidadefmpatos" target="_blank" rel="noopener noreferrer" className='social'>
              <FaFacebookSquare />
            </a>
          </li>
          <li>
            <a href="https://instagram.com/universidadefmpatos" target="_blank" rel="noopener noreferrer" className='social'>
              <FaInstagram />
            </a>
          </li>
          <li>
            <a href="https://www.radios.com.br/aovivo/radio-universidade-1051-fm/142673" target="_blank" rel="noopener noreferrer" className='social'>
              <img src={radiosNet} alt='Radios Net' />
            </a>
          </li>
        </ul>
      </div>
    </>
  )
}