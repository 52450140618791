const Schedule = {
    "Segunda": [
        { schedule: '00:00', program: 'Madrugada 105' },
        { schedule: '05:00', program: 'Sala de Reboco' },
        { schedule: '06:00', program: 'Fala Cidade' },
        { schedule: '08:00', program: 'Manhã Classe A com Arisângela Nóbrega' },
        { schedule: '11:00', program: 'Playlist' },
        { schedule: '13:00', program: 'Mistura Fina' },
        { schedule: '14:00', program: 'Rádio Play' },
        { schedule: '18:00', program: 'Músicas' },
        { schedule: '19:00', program: 'Voz do Brasil' },
        { schedule: '20:00', program: 'Hits da 105' },
        { schedule: '22:00', program: 'Universidade Retrô' },
    ],
    "Terça": [
        { schedule: '00:00', program: 'Madrugada 105' },
        { schedule: '05:00', program: 'Sala de Reboco' },
        { schedule: '06:00', program: 'Fala Cidade' },
        { schedule: '08:00', program: 'Manhã Classe A com Arisângela Nóbrega' },
        { schedule: '11:00', program: 'Playlist' },
        { schedule: '13:00', program: 'Mistura Fina' },
        { schedule: '14:00', program: 'Rádio Play' },
        { schedule: '18:00', program: 'Músicas' },
        { schedule: '19:00', program: 'Voz do Brasil' },
        { schedule: '20:00', program: 'Hits da 105' },
        { schedule: '22:00', program: 'Universidade Retrô' },
    ],
    "Quarta": [
        { schedule: '00:00', program: 'Madrugada 105' },
        { schedule: '05:00', program: 'Sala de Reboco' },
        { schedule: '06:00', program: 'Fala Cidade' },
        { schedule: '08:00', program: 'Manhã Classe A com Arisângela Nóbrega' },
        { schedule: '11:00', program: 'Playlist' },
        { schedule: '13:00', program: 'Mistura Fina' },
        { schedule: '14:00', program: 'Rádio Play' },
        { schedule: '18:00', program: 'Músicas' },
        { schedule: '19:00', program: 'Voz do Brasil' },
        { schedule: '20:00', program: 'Hits da 105' },
        { schedule: '22:00', program: 'Universidade Retrô' },
    ],
    "Quinta": [
        { schedule: '00:00', program: 'Madrugada 105' },
        { schedule: '05:00', program: 'Sala de Reboco' },
        { schedule: '06:00', program: 'Fala Cidade' },
        { schedule: '08:00', program: 'Manhã Classe A com Arisângela Nóbrega' },
        { schedule: '11:00', program: 'Playlist' },
        { schedule: '13:00', program: 'Mistura Fina' },
        { schedule: '14:00', program: 'Rádio Play' },
        { schedule: '18:00', program: 'Músicas' },
        { schedule: '19:00', program: 'Voz do Brasil' },
        { schedule: '20:00', program: 'Hits da 105' },
        { schedule: '22:00', program: 'Universidade Retrô' },
    ],
    "Sexta": [
        { schedule: '00:00', program: 'Love Time' },
        { schedule: '01:00', program: 'Palavra do Bispo' },
        { schedule: '01:30', program: 'Madrugada 105' },
        { schedule: '05:00', program: 'Sala de Reboco' },
        { schedule: '06:00', program: 'Fala Cidade' },
        { schedule: '08:00', program: 'Manhã Classe A com Arisângela Nóbrega' },
        { schedule: '11:00', program: 'Playlist' },
        { schedule: '12:00', program: 'Mistura Fina' },
        { schedule: '14:00', program: 'Rádio Play' },
        { schedule: '18:00', program: 'Playlist' },
        { schedule: '19:00', program: 'Voz do Brasil' },
        { schedule: '20:00', program: 'Hits da 105' },
        { schedule: '23:00', program: 'Love Time' },
    ],
    "Sábado": [
        { schedule: '00:00', program: 'Love Time' },
        { schedule: '01:00', program: 'Palavra do Bispo' },
        { schedule: '01:30', program: 'Madrugrada 105' },
        { schedule: '05:00', program: 'Bom dia sertão' },
        { schedule: '08:00', program: 'Playlist' },
        { schedule: '12:00', program: 'Playlist' },
        { schedule: '13:00', program: 'Mistura Fina' },
        { schedule: '14:00', program: 'Playlist' },
        { schedule: '18:00', program: 'Playlist' },
        { schedule: '23:00', program: 'Love Time' },
    ],
    "Domingo": [
        { schedule: '00:00', program: 'Love Time' },
        { schedule: '02:00', program: 'Madrugada 105' },
        { schedule: '05:00', program: 'Bom dia Sertão' },
        { schedule: '06:00', program: 'Playlist Musical' },
        { schedule: '07:00', program: 'Santa Missa com Padre Jair Tomazela' },
        { schedule: '08:00', program: 'Domingo 105' },
        { schedule: '13:00', program: 'Hits da 105' },
        { schedule: '18:00', program: 'Hits da 105' },
        { schedule: '23:00', program: 'Love Time' },
    ],
};

export default Schedule;