import React from 'react';

import { FaWhatsapp } from 'react-icons/fa';
import { FiMail } from 'react-icons/fi';

import './styles.css';

export default function Contato() {
  return (
    <div className="contato">

      <h2>Entre em contato conosco por nossos E-mail e WhatsApp</h2>

      <div className="contato-info">

        <div className="contato-row">
          <div>
            <FiMail />
            <h3>Administração</h3>
            <h4>administrativo@universidade.com.br</h4>
          </div>

          <div>
            <FiMail />
            <h3>Redação</h3>
            <h4>jornalismo@universidade.com.br</h4>
          </div>
        </div>

        <div className="contato-row">
          <div>
            <FiMail />
            <h3>Comercial</h3>
            <h4>comercial@universidade.com.br</h4>
          </div>

          <div>
            <FaWhatsapp />
            <h3>WhatsApp</h3>
            <h4>(83) 98134-7632</h4>
          </div>
        </div>

      </div>

    </div>
  );
}