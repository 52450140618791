import React from 'react';
import './styles.css';
import imagemDescricao from '../../assets/imagem-radio-descricao.jpg';

export default function SobreNos() {

  return (
    <div className='sobreAradio'>

      <div className='aradio-titulo'>
        <h2> Rádio Universidade FM Patos 105,1 </h2>
      </div>

      <div className='aradio-descricao'>
        <p> Com o intuito de oferecer boa qualidade nas ondas do rádio paraibano,
        a Rádio Universidade FM, tem como proposta levar ao ouvinte o melhor da música e
        da informação tendo como referência o bom gosto e estilo.  </p>

        <p> A Universidade FM tem uma grade de programação dedicada ao ouvinte que detém
        conhecimento e bom gosto musical e aqueles ouvintes que gostam de um noticiário
        objetivo e atual. Além da programação musical, a rádio universidade tem um
        noticiário de hora em hora com as principais notícias daquela hora, além de três
        noticiários de maior duração com um resumo das principais notícias do dia, além de
            reportagens e entrevistas.</p>

        <p> A emissora é transmitira pela frequência 105.1 MHz e também tem mantido e atraído
        admiradores, que hoje se multiplicam mundo afora graças ao acesso online à programação
            através dos aplicativos de rádio ou pelo portal da emissora. </p>

        <img src={imagemDescricao} alt=''></img>
      </div>

    </div>


  )
}