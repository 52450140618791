import React from 'react';

import { FaFacebookSquare, FaInstagram } from 'react-icons/fa';
import banner from '../../assets/banner.jpg';

import './styles.css';

export default function Footer() {

  return (
    <div className='footer'>
      <div className='redesSociais'>
        <a href="https://www.facebook.com/universidadefmpatos" target="_blank" rel="noopener noreferrer">
          <FaFacebookSquare />
        </a>
        <a href=" https://instagram.com/universidadefmpatos" target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </a>
      </div>
      <div className='radiosNet'>
        <a href="https://www.radios.com.br/aovivo/radio-universidade-1051-fm/142673" target="_blank" rel="noopener noreferrer">
          <img src={banner} alt="RadiosNet" />
        </a>
      </div>
    </div>
  )

}