import React, { useState } from 'react';
import Schedule from '../../common/data/schedule';
import './styles.css';

export default function Programacao() {
  const daysOfWeek = Object.keys(Schedule);
  const [daySelected, setDaySelected] = useState(Schedule.Segunda);
  const [loading, setLoading] = useState(false);

  const setDayOfWeek = (day) => {
    setLoading(true);
    setTimeout(() => {
      setDaySelected(Schedule[day]);
      setLoading(false);
    }, 0)
  };

  return (
    <div className='programacao'>
      <div className='listas-semanais'>
        <ul>
          {daysOfWeek.map((day, index) => (
            <li key={index} className='menu-item animation'>
              <p onClick={() => setDayOfWeek(day)}>{day}</p>
            </li>
          ))}
        </ul>
      </div>

      <div className='listas' id='segunda' >
        {!loading && (
          <ul>
            {daySelected.map((program, index) => (
              <li key={index}>
                <div className='programacao-horario'>
                  <h2>{program.schedule}</h2>
                </div>

                <div className='programacao-conteudo'>
                  <h3>{program.program}</h3>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}