import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Contato from './pages/Contato';
import Programacao from './pages/Programacao';
import SobreNos from './pages/SobreNos';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Programacao} />
      <Route path="/contato" component={Contato} />
      <Route path="/sobre-nos" component={SobreNos} />
    </Switch>
  )
}